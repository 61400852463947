//import logo from './logo.svg';
//import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import React from 'react';
import UserList from './components/UserList';
import AddUser from "./components/AddUser";
import EditUser from "./components/EditUser";


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<UserList />}/>
      <Route path="/add" element={<AddUser />}/>
      <Route path="edit/:id" element={<EditUser />}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
