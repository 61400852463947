import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {API_BASE_URL} from '../consts'

const AddUser = () => {
const [name, setName] = useState ("");
const [email, setEmail] = useState ("");
const [gender, setGender] = useState ("Male");
const navigate = useNavigate();

// oke, misal sudah dideploy, base url apinya bukan localhost:5000 lagi, tapi jadi /backend (sesuai path backend yang dibuat di Node.js App cpanel tadi)

const saveUser = async (e) =>{
    e.preventDefault();
    try{
        await axios.post(API_BASE_URL + '/users',{
            name,
            email,
            gender
        });
        navigate("/");
    } catch (error) {
        console.log(error);
    }
}

  return (
    <div className="columns mt-5 is-centered">
        <div className="column is-half">
            <form onSubmit={saveUser}>
                <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                        <input
                        type="text"
                        className="input"
                        value={name}
                        onChange={(e)=> setName(e.target.value)}
                        placeholder='Name'/>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        <input
                        type="text"
                        className="input"
                        value={email}
                        onChange={(e)=> setEmail(e.target.value)}
                        placeholder='Email'/>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Gender</label>
                    <div className="control">
                        <div className="select is-fullwidth">
                            <select
                            value={gender}
                            onChange={(e)=>
                            setGender(e.target.value)}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                   <button type='submit' className='button is-succes'>Save</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddUser